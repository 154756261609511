
import { Component, Vue } from 'vue-property-decorator';
import { QExpansionItem } from 'quasar';

import { cloneDeep, isNumber, merge } from 'lodash-es';
import WsMessage from '@WS_Components/WsMessage.vue';
import { commonModel } from './commonModel';

const defaults: {
  message: {
    icon: string | undefined;
    messages: string | string[];
    description: string;
    button: string | undefined;
  };
} = {
  message: {
    icon: undefined,
    messages: 'Message title',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus sit amet 79,92 €  Odio lorem scelerisque ac ' +
      'et lectus iaculis. Tempus leo aliquam aenean dolor. Ac gravida ornare vivamus consequat magna donec tellus.' +
      ' Inline Button',
    button: undefined,
  },
};

@Component({
  components: {
    QExpansionItem,
    WsMessage,
  },
})
export default class Message extends Vue {
  model = merge(commonModel, defaults, {
    container: { size: 4 },
    page: {
      style: {
        background: 'white',
      },
    },
  });
  patternBg = true;
  buttonKey = 'Button key';
  messageType = '';
  expansion = false;
  numberOfMessages = '1';

  get messages() {
    const messageCfg = cloneDeep(this.model.message);
    const icon = messageCfg.icon || true;
    const button = {
      translationKey: this.buttonKey,
      action: 'www.google.com',
    };
    const numberOfMessages = parseInt(this.numberOfMessages);
    const count = isNumber(numberOfMessages) ? numberOfMessages : 0;
    if (count > 1) {
      const msgs = [];
      for (let i = 0; i < count; i++) {
        msgs.push(messageCfg.messages as string);
      }
      messageCfg.messages = msgs;
    }

    return [
      // default
      { ...messageCfg },
      { ...messageCfg, inline: true },
      { ...messageCfg, banner: true },

      // success
      { ...messageCfg, type: 'success' },
      { ...messageCfg, inline: true, type: 'success' },
      { ...messageCfg, banner: true, type: 'success' },

      // alert
      { ...messageCfg, type: 'alert' },
      { ...messageCfg, inline: true, type: 'alert' },
      { ...messageCfg, banner: true, type: 'alert' },

      // neutral
      { ...messageCfg, type: 'neutral' },
      { ...messageCfg, inline: true, type: 'neutral' },
      { ...messageCfg, banner: true, type: 'neutral' },

      // sizes
      { wsDevTitle: 'Sizes' },
      { ...messageCfg, banner: true },
      { ...messageCfg, banner: true, size: 'medium' },
      { ...messageCfg, banner: true, size: 'small' },

      // icon
      { wsDevTitle: 'Icon' },
      { ...messageCfg, banner: true, icon },
      { ...messageCfg, banner: true, icon, size: 'medium' },
      { ...messageCfg, banner: true, icon, size: 'small' },

      // button
      { wsDevTitle: 'Button' },
      { ...messageCfg, banner: true, button },
      { ...messageCfg, banner: true, button, size: 'medium' },
      { ...messageCfg, banner: true, button, size: 'small' },

      // icon + button
      { wsDevTitle: 'Icon + button' },
      { ...messageCfg, banner: true, icon, button },
      { ...messageCfg, banner: true, icon, button, size: 'medium' },
      { ...messageCfg, banner: true, icon, button, size: 'small' },
    ];
  }

  created() {
    this.$emit('model', this.model);
  }
}
