
import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';
import { jsonFilter } from '@loopia-group/utils';
import WsBack from '@WS_Components/WsBack.vue';

@Component({
  filters: {
    json: jsonFilter,
  },
  components: {
    WsBack,
  },
})
export default class StatePage extends Vue {
  store = store;
}
