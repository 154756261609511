
import { Component, Prop, Vue } from 'vue-property-decorator';
import config from '@/services/config.service';

@Component
export default class Common extends Vue {
  @Prop()
  readonly model!: any;
  config = config;

  get pageStyle() {
    return this.model?.page?.style || {};
  }

  get modelContainer() {
    return this.model?.container || {};
  }
}
