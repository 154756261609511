
import { Component, Prop, Vue } from 'vue-property-decorator';

import WsBack from '@WS_Components/WsBack.vue';
import { ROUTENAMES } from '@/const.enum';
import Common from './components/Common.vue';
import Button from './components/Button.vue';
import RadioCheck from './components/RadioCheck.vue';
import Input from './components/Input.vue';
import Typography from './components/Typography.vue';
import Message from './components/Message.vue';
import HostingTutorial from './components/HostingTutorial.vue';
import Card from './components/Card.vue';

@Component({
  components: {
    Common,
    WsBack,
    Button,
    RadioCheck,
    Input,
    Typography,
    Message,
    HostingTutorial,
    Card,
  },
})
export default class ComponentsTestPage extends Vue {
  @Prop()
  readonly component!: string;
  ROUTENAMES = ROUTENAMES;
  cmps = [
    'Button',
    'RadioCheck',
    'Input',
    'Typography',
    'Message',
    'HostingTutorial',
    'Card',
  ];
  model = {};
}
