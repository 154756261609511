
import { Component, Vue, Watch } from 'vue-property-decorator';
import { QField, QBtn, QIcon, QTooltip } from 'quasar';

import WsInput from '@WS_Components/WsInput.vue';
import WsPhoneInput from '@WS_Components/WsPhoneInput.vue';
import WsEmailsInput from '@WS_Components/WsEmailsInput.vue';
import WsPasswordInput from '@WS_Components/WsPasswordInput.vue';
import WsSelect from '@WS_Components/WsSelect.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import DomainSearchBar from '@/components/domain-checker/DomainSearchBar.vue';
import { commonModel } from './commonModel';

const defaults = {
  input: {
    model: '',
    emailsModel: '',
    phoneModel: '',
    passwordModel: '',
    label: 'Example label',
    placeholder: 'Example placeholder',
    optional: false,
    disabled: false,
    errorState: false,
    errorText: 'Example error',
  },
  btn: {
    disabled: false,
    loading: false,
  },
};

@Component({
  components: {
    QField,
    QBtn,
    QIcon,
    QTooltip,
    WsInput,
    WsPhoneInput,
    WsEmailsInput,
    WsPasswordInput,
    WsSelect,
    WsButton,
    WsIcon,
    DomainSearchBar,
  },
})
export default class InputTestPage extends Vue {
  model = {
    ...commonModel,
    ...defaults,
    container: { ...commonModel.container, size: 6 },
  };
  selectedOption = 'one';
  customValue = '';

  created() {
    this.$emit('model', this.model);
  }

  @Watch('model.input.errorState')
  onVariationChange() {
    const fields = this.$refs.field as (WsInput | WsSelect)[];
    fields.forEach(field => {
      (field as any).validate?.();
    });
  }

  hasError() {
    return this.model.input.errorState ? this.model.input.errorText : true;
  }
}
