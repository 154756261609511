import Vue from 'vue';
import { AxiosResponse } from 'axios';

import { Cart, CartItem, StandaloneProduct } from '@/types';
import {redirect} from '@loopia-group/utils';
import {
  cloneDeep,
  get,
  mergeWith,
  omit,
  pick,
} from 'lodash-es';

import {
  addToCart,
  setBillingProfile,
  setDomainProfile,
  setPaymentOption,
  updateItem,
} from '@/services/cart/cart-api.service';
import { signUp } from '@/services/user-api.service';
import { getShortUID } from '@/services/mocks/utilities';
import { setQueryParam } from '@/utilities';
import { signupDataMock, cartMockData } from '@/services/mocks';
import store from '@/store';
import { StateMutations, StoreActions } from '@/store/const.enum';
import { STEPS } from '@/services/const.enum';
import { navigateToStep, setEditMode } from '@/services/order.service';
import ThemeSwitcher from '@/components/developer/ThemeSwitcher.vue';

// eslint-disable-next-line no-console
console.log(' <<< ⚠⚠⚠ <<< DEVELOPER TOOLS LOADED >>> ⚠⚠⚠ >>>');

Vue.set(Vue.prototype.$wsDev, 'component', ThemeSwitcher);
Vue.set(Vue.prototype.$wsDev, 'fillSignupData', (cmp: any) => {
  Vue.set(cmp, 'newUser', signupDataMock);
  Vue.set(
    cmp,
    'domainProfileAdditionalInfo',
    signupDataMock.domainProfileAdditionalInfo
  );
  if (cmp.isSwedishWorkflow) {
    Vue.set(cmp.newUser, 'domainProfile', {
      ...signupDataMock.billingProfile,
    });
    Vue.set(
      cmp.newUser.domainProfile!,
      'email',
      signupDataMock.billingProfile.emails[0]
    );
    Vue.set(cmp.newUser, 'billingProfile', { type: 'personal' });
  }
});

let cartCheckoutReady = false;
export async function getCartToInstantCheckoutState() {
  const realCart = store.state.cart;

  if (!realCart?.items?.length) {
    // need to put first item to create cart if it is empty
    await addToCart({
      code: 'domain-register',
      properties: {
        domain: 'first-' + getShortUID() + '.sk',
        tld: 'sk',
      },
      sellType: 'direct',
    });
  }

  if (!realCart?.user) {
    // need to register user
    const response = await signUp({
      ...signupDataMock,
      termsAndConditionsConsent: true,
      thirdPartyConsent: true,
    });

    const redirectUrl = setQueryParam(
      response.data?.redirect,
      'redirectAfterLogin',
      window.location.href
    );

    redirect(redirectUrl);
  }

  _getCartToInstantCheckoutState(realCart);
}

export function devUtilsToggle(state?: boolean) {
  store.commit(StateMutations.SET_STATE, {
    prop: 'devUtils',
    value: typeof state === 'boolean' ? state : !store.state.devUtils,
    persistent: true,
  });
}

function _getCartToInstantCheckoutState(realCart: Cart) {
  const newCart: Cart = mergeWith(
    {},
    cloneDeep(realCart),
    omit(cartMockData, ['items', 'uuid']),
    (objValue: any, srcValue: any, key: string) => {
      if (key === 'uuid') {
        return objValue;
      } else {
        return undefined;
      }
    }
  );

  if (!cartCheckoutReady) {
    if (get(realCart, 'items.length', 0) < 5) {
      addMockItems(realCart, (cartMockData as unknown) as Cart);
    }

    // setTimeout to wait for cart creation
    // TODO can be replaced by using Promise.all in addMockItems
    setTimeout(() => {
      setBillingProfile({
        billingProfileId:
          get(store.state, 'billingProfiles[0].id') ||
          newCart.billingProfile?.id,
      });
      setDomainProfile({
        domainProfileId:
          get(store.state, 'domainProfiles[0].id') || newCart.domainProfile?.id,
      });
      newCart.paymentOption ?? setPaymentOption(newCart.paymentOption as any);
      cartCheckoutReady = true;
    }, 3000);

    store.dispatch(StoreActions.SET_USER_STEP, {
      step: STEPS.RECAPITULATION,
    });
    navigateToStep(STEPS.RECAPITULATION);
    setEditMode(null);
  }
}

function addMockItems(realCart: Cart, newCart: Cart) {
  ((newCart.items as unknown) as CartItem[]).forEach(item => {
    if (
      // has real cart such item already?
      !get(realCart, 'items', []).some((i: CartItem) => {
        return (
          i.id === item.id ||
          (i.code === item.code &&
            i.properties &&
            item.properties &&
            i.properties.domain === item.properties.domain &&
            i.properties.domain === item.properties.domain)
        );
      })
    ) {
      addToCart({
        sellType: 'direct',
        ...pick(item, ['code', 'properties']),
      }).then((response: AxiosResponse) =>
        updateItem({
          ...item,
          id: response.data.items[response.data.items.length - 1].id,
        })
      );
    }
  });
}

const productListTmp: Partial<StandaloneProduct>[] = [
  {
    code: 'domain-register',
    properties: { domain: 'example.com', period: 12 },
  },
  {
    code: 'domain-transfer',
    properties: { domain: 'example-two.com', period: 12 },
  },
  {
    code: 'easy-seo',
    properties: { period: 12 },
  },
  {
    code: 'hosting',
    properties: { capacity: 2, domainsCount: 1 },
  },
  {
    code: 'hosting-wp',
    properties: { capacity: 2, domainsCount: 1 },
  },
  {
    code: 'hosting-business',
  },
  {
    code: 'email',
    properties: { type: '', domain: 'example-three.com', localPart: '' },
  },
  {
    code: 'site-builder',
    properties: { type: '' },
  },
  {
    code: 'redirect',
    properties: { domain: 'example-four.com' },
  },
  {
    code: 'vps',
    properties: {
      name: '',
      osName: '',
      osTemplate: '',
      capacity: '',
      cpu: '',
      ram: '',
      management: '',
      backup: '',
      monitoring: '',
    },
  },
  {
    code: 'vdc',
    properties: {
      name: '',
      cpu: '',
      ram: '',
      ip: '',
      management: '',
      backup: '',
      monitoring: '',
    },
  },
  {
    code: 'storage',
    properties: { name: '', capacity: '' },
  },
  {
    code: 'ssl-single',
  },
  {
    code: 'ssl-wildcard',
  },
];
productListTmp.forEach(product => (product.sellType = 'direct'));
export const productList = productListTmp as StandaloneProduct[];
