
import { Component, Vue } from 'vue-property-decorator';
import { RouteRecord } from 'vue-router';
import { QPage, QBreadcrumbs, QBreadcrumbsEl } from 'quasar';
import { mdiHome } from '@quasar/extras/mdi-v4';

@Component({
  components: {
    QPage,
    QBreadcrumbs,
    QBreadcrumbsEl,
  },
  data() {
    return {
      mdiHome,
    };
  },
})
export default class DeveloperPage extends Vue {
  get routes() {
    return this.$route.matched.filter(
      (route: RouteRecord) => route.name || route.meta?.name
    );
  }
}
