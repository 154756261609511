
import { Component, Vue } from 'vue-property-decorator';

import { commonModel } from './commonModel';

const defaults = {
  typo: {
    text: '',
  },
};

@Component
export default class TypographyTestPage extends Vue {
  model = { ...commonModel, ...defaults };

  created() {
    this.$emit('model', this.model);
  }
}
