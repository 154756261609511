
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  QCard,
  QCardSection,
  QItem,
  QItemSection,
  QItemLabel,
  QIcon,
  QTree,
  QSelect,
  QForm,
  QInput,
  QBanner,
  QBtn,
  QSeparator,
} from 'quasar';
import {
  mdiDeveloperBoard,
  mdiSettings,
  mdiAccountBox,
  mdiDns,
  mdiCartPlus,
  mdiClose,
} from '@quasar/extras/mdi-v4';
import { RouteConfig } from 'vue-router';
import { mapState } from 'vuex';

import { cloneDeep, isNumber, get } from 'lodash-es';
import { i18nService } from '@WS_UIkit';

import WsButton from '@WS_Components/WsButton.vue';
import {
  getCartToInstantCheckoutState,
  productList,
  devUtilsToggle,
} from '@/services/developer.service';
import config, { AppConfig } from '@/services/config.service';
import { routes, safePush } from '@/router';
import { ROUTENAMES } from '@/const.enum';
import { StandaloneProduct, Cart } from '@/types';
import { addToCart } from '@/services/cart/cart-api.service';

interface LangOption {
  label: string;
  value: string;
}

const options: LangOption[] = Object.entries(
  config.languages as { [key: string]: string }
).map(entry => {
  return { value: entry[0], label: entry[1] };
});

@Component({
  components: {
    QCard,
    QCardSection,
    QItem,
    QItemSection,
    QItemLabel,
    QIcon,
    QTree,
    QSelect,
    QForm,
    QInput,
    QBanner,
    QBtn,
    QSeparator,
    WsButton,
  },
  computed: {
    ...mapState(['cart']),
  },
})
export default class DashboardPage extends Vue {
  readonly cart!: Cart;
  wsDev!: any; // globally provided namspace object src/WS_UIkit/src/main.ts
  config: AppConfig = config;
  ROUTENAMES: any = ROUTENAMES;
  nodes: any;
  expandedKeys: string[] = ['developer', 'home', 'cart'];
  properties: { [key: string]: any } = Object.create(null);
  products: StandaloneProduct[] = productList;
  selectedProduct: StandaloneProduct | null = null;
  loading = false;
  error: Error | null = null;
  selected: any;
  getReadyToCheckout = getCartToInstantCheckoutState;

  data() {
    return {
      options,
      isNumber,
      mdiDeveloperBoard,
      mdiSettings,
      mdiAccountBox,
      mdiDns,
      mdiCartPlus,
      mdiClose,
      devUtilsToggle,
    };
  }

  // computed
  get selectedProductProps(): string[] {
    return this.selectedProduct
      ? Object.keys(this.selectedProduct!.properties || Object.create(null))
      : [];
  }

  created() {
    this.nodes = routesToNodes(routes);
    const currentLang = i18nService.getLang();
    this.selected = {
      label: get(config, `languages[${currentLang}]`) || 'translator',
      value: currentLang,
    };
  }

  @Watch('selectedProduct')
  onSelectedProductChanged() {
    this.properties = Object.create(null);
  }

  langChange: (lang: LangOption) => void = selected =>
    i18nService.setLang(selected.value);

  submit() {
    if (!this.selectedProduct) {
      return (this.error = new Error('Product not selected.'));
    }
    const product: StandaloneProduct = cloneDeep(this.selectedProduct);
    product.properties = this.properties || {};
    product.sellType = 'direct';
    this.selectedProductProps.forEach((prop: string) => {
      if (!this.selectedProduct || !this.selectedProduct.properties) {
        return;
      }
      if (isNumber(this.selectedProduct.properties[prop])) {
        product.properties![prop] = parseFloat(product.properties![prop]);
      }
    });
    this.loading = true;
    addToCart(product)
      .catch(error => (this.error = error))
      .finally(() => (this.loading = false));
  }

  get devUtilsOn() {
    return this.$store.state.devUtils;
  }
}

interface QTreeItem {
  label: string;
  handler?: Function;
  children?: QTreeItem[];
}

type ComplexItem = Partial<QTreeItem & RouteConfig>;

function routesToNodes(routes: RouteConfig[]) {
  const nodes = cloneDeep(routes) as ComplexItem[];
  const dfs = (stack: ComplexItem[], head: ComplexItem) =>
    ((head.children as ComplexItem[]) || []).concat(stack);
  let unnamedCounter = 1;

  const traverse = (stack: ComplexItem[]): void => {
    if (stack.length === 0) {
      return;
    }

    const [head, ...tail] = stack;
    // console.log('head -> ', head);
    const headLabel = head.name || head.meta?.name;
    if (headLabel) {
      head.label = headLabel;
      head.handler = navigateToItem;
    } else {
      head.label = 'unnamed' + unnamedCounter++;
    }
    return traverse(dfs(tail, head));
  };

  traverse(nodes);
  return nodes;
  //
  // example of nodes
  //
  //     [
  //       {
  //         label: 'Root',
  //         handler: (node) => this.myclick(node),
  //         children: [
  //           {
  //             label: 'NodeA',
  //             handler: (node) => this.myclick(node),
  //             children: [
  //               { label: 'NodeA1', handler: (node) => this.myclick(node)},
  //               { label: 'NodeA2', handler: (node) => this.myclick(node) }
  //             ]
  //           },
  //           {
  //             label: 'NodeB',
  //             handler: (node) => this.myclick(node),
  //             children: [
  //               { label: 'NodeB1', handler: (node) => this.myclick(node)},
  //               { label: 'NodeB2', handler: (node) => this.myclick(node) }
  //             ]
  //           }
  //         ]
  //       }
  //     ]
}

function navigateToItem(item: ComplexItem) {
  const { name, path } = item;
  safePush(name ? { name } : { path });
}
