
import { Component, Vue, Watch } from 'vue-property-decorator';

import WsRadio from '@WS_Components/WsRadio.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import { config } from '@/services/config.service';
import commonModel from './commonModel';
import { Theme } from '@loopia-group/services';

const defaults = {
  cmp: { label: 'radio and checkbox label', value: true },
};

@Component({
  components: {
    WsRadio,
    WsCheckbox,
  },
})
export default class RadioCheck extends Vue {
  model = { ...commonModel, ...defaults };
  config = config;
  Theme = Theme;

  created() {
    this.$emit('model', this.model);
  }

  @Watch('model.cmp.value')
  onVariationChange(value: string) {
    if (['true', 'false'].includes(value)) {
      this.model.cmp.value = value === 'true';
    }
  }
}
