
import { Component, Vue } from 'vue-property-decorator';
import { QExpansionItem } from 'quasar';

import { merge } from 'lodash-es';
import WsCard from './../../WsCard.vue';
import { commonModel } from './commonModel';

const defaults: {
  card: {
    content: string;
    cfg: {
      spacing: string;
      spacingTablet: string;
      spacingMobile: string;
    };
    description: string;
  };
} = {
  card: {
    content: 'Card content',
    cfg: {
      spacing: 'lg',
      spacingTablet: 'md',
      spacingMobile: 'sm',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus sit amet 79,92 €  Odio lorem scelerisque ac ' +
      'et lectus iaculis. Tempus leo aliquam aenean dolor. Ac gravida ornare vivamus consequat magna donec tellus. ' +
      'Inline Button',
  },
};

@Component({
  components: {
    QExpansionItem,
    WsCard,
  },
})
export default class Message extends Vue {
  model = merge(commonModel, defaults, {
    container: { size: 6 },
    page: {
      style: {
        background: 'white',
      },
    },
  });
  numberOfItems = 3;

  get cfgs() {
    return [
      // verbose to having it reactive
      {
        spacing: this.model.card.cfg.spacing,
        spacingTablet: this.model.card.cfg.spacingTablet,
        spacingMobile: this.model.card.cfg.spacingMobile,
      },
      {
        spacing: 'xl',
        spacingTablet: 'lg',
        spacingMobile: 'md',
      },
      {
        spacing: 'md',
        spacingTablet: 'sm',
        spacingMobile: 'xs',
      },
      {
        spacing: '2xl',
        spacingTablet: 'xl',
        spacingMobile: 'md',
      },
    ];
  }

  created() {
    this.$emit('model', this.model);
  }
}
