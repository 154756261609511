
import { Component, Vue, Watch } from 'vue-property-decorator';
import HostingTutorial from '@/components/hostingTutorial/HostingTutorial.vue';
import HostingTutorialProduct from '@/components/hostingTutorial/HostingTutorialProduct.vue';
import { commonModel } from './commonModel';
import WsButton from '@WS_Components/WsButton.vue';
import { HostingTutorialStandaloneProduct } from '@/types';
import { hostingTutorialMockProducts } from '@/__mocks__/hostingTutorial';

@Component({
  components: {
    HostingTutorial,
    HostingTutorialProduct,
    WsButton,
  },
})
export default class HostingTutorialPage extends Vue {
  product: HostingTutorialStandaloneProduct = hostingTutorialMockProducts[1];
  active: boolean = false;
  model = {
    ...commonModel,
    page: {
      ...commonModel.page,
      style: {
        ...commonModel.page?.style,
        background: '#E5E5E5',
      },
    },
    container: { size: 2 },
  };

  created() {
    this.$emit('model', this.model);
  }

  @Watch('model.btn.dark')
  onDarkModeChanged(dark: boolean) {
    this.model.page.style.background = dark ? 'var(--ws-base150)' : '#E5E5E5';
  }

  setActive() {
    this.active = true;
  }

  toggleProduct() {
    this.active = !this.active;
  }
}
