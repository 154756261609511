
import { Component, Vue } from 'vue-property-decorator';

import config, { AppConfig } from '@/services/config.service';
import * as env from '@env';
import { jsonFilter } from '@loopia-group/utils';
import WsBack from '@WS_Components/WsBack.vue';

@Component({
  filters: {
    json: jsonFilter,
  },
  components: {
    WsBack,
  },
})
export default class ConfigPage extends Vue {
  config: AppConfig = config;
  env: any = env;
}
